import 'rc-slider/assets/index.css';

import Slider from 'rc-slider';
import React, { useEffect, useReducer,useState } from 'react';

import waiting from '../../images/Calculatorpage1.png';
import payment from '../../images/images/payment.png';
import shield from '../../images/images/shield.png';


let ranOnce = false;

const Page1 = ( props ) => {

  const [ _, forceUpdate ] = useReducer( x => x + 1, 0 );

  const [ marks, styleMarks ] = useState( {
    30: { label: '30' },
    45: { label: '45' },
    60: { label: '60' },
    75: { label: '75' }
  } );

  const styler = () => {
    Object.keys( marks ).forEach( age => {
      if ( +age === props.age ){
        marks[age]['style'] = { top: '15px', font: 'bold 16px Poppins', color: '#2a2727' };
        ranOnce = true;
      }
      else
        marks[age]['style'] = {};
    } );
    styleMarks( marks );

  };

  useEffect( styler, [props.age] );

  useEffect( () => {

    let clear = setInterval( () => {
      if ( ranOnce )
        clearInterval( clear );
      styler();
      forceUpdate();
    }, 1000 );

    return function cleanup(){
      if( !ranOnce ) clearInterval( clear );
    };
  }, [] );

  return (
    <div className="page1">
      <img src={waiting} className="img-fluid waitimg" alt="wait"/>

      <div className="text-center">What is your current age?</div>

      <div className='fs-slider-block'>
        <div className="slider-aligned">
          <Slider min={20} max={80} value={props.age} step={15} onAfterChange={props.onAfterChangeAge}
            onChange={props.onAgeChange} marks={marks}
          />

          <div id="slider-tick" style={{ left: `${( props.age - 20 ) * 100 / 60}%` }}>
          </div>
        </div>
      </div>

      <div className="d-flex premium-data">
        <section className='rectangle d-flex'>
          <div className="w-25">
            <img src={shield} className="img-fluid" style={{ width: '21px', height: '25px' }} alt="shield"/>
          </div>

          <div className="w-75 flex-column">
            <div className="px12"> Premium <div className="d-none d-md-inline">Amount </div> </div>

            <div className="d-flex">
              <div className="info">$ {props.premiumAmount}</ div>

              <div className="time">&nbsp; per month </div>
            </div>
          </div>
        </section>

        <section className='rectangle d-flex'>
          <div className="w-25">
            <img src={payment} style={{ height: '25px', width: '25px' }} className="img-fluid" alt="payment"/>
          </div>

          <div className="w-75 flex-column">
            <div className="px12"> Total <div className="d-none d-md-inline">Amount</div> Spent </div>

            <div className="d-flex">
              <div className="info">$ {props.totalAmount}</ div>

              <div className="time">&nbsp; 25 year term</div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Page1;
